
  import { Component, Watch } from 'vue-property-decorator'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import LaborDataContent from '@/components/person/laborData/LaborDataContent.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import ReferenceAlignment from '@/components/person/ReferenceAlignment.vue'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { isEmpty } from '@/utils/general'
  import { BankDataFulled, BankDataFulledBiPersonal } from '@/store/persons/bank/state'
  import { BankData, Details } from '@/utils/generalInterface'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { FinancialCategory } from '@/entities/finance'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { PersonAccount } from '@/entities/persons'

@Component({
  components: {
    GCostField,
    BaseForm,
    BankDataAlignment,
    ReferenceAlignment,
    MobileForm,
    FormTitle,
    DesktopForm,
    GDatePicker,
    AddressAlignment,
    LaborDataContent,
    GAlert,
  },
  methods: {
    ...mapMutations('persons/bank', [
      'setAccountAdded',
      'setAccountFound',
      'setBiPersonalInfo',
      'setAddAccountState',
      'setRepeatedAccount',
    ]),
    ...mapActions('persons/bank', [
      'findBankAccount',
      'updateBankDetails',
      'createBankDetails',
      'loadAccountTypes',
      'loadBanks',
    ]),
  },
  computed: {
    ...mapGetters('persons/bank', [
      'addAccount',
      'isAccountAdded',
      'accountFound',
      'banks',
      'accountTypes',
      'biPersonalInfo',
    ]),
  },
})
  export default class BankDataForm extends BaseCustomForm {
  setBiPersonalInfo!: (biPersonalInfo: BankDataFulled | null) => void;
  createBankDetails!: (bank: BankData) => Promise<any>;
  updateBankDetails!: ({ bank, personId, biPersonal }: {
    bank: BankDataFulled;
    personId: string;
    biPersonal: boolean;
  }) => Promise<any>;

  accountFound!: BankDataFulled | null;
  accountTypes!: Details[];
  categories: FinancialCategory[] = []
  banks!: Details[];
  loadAccountTypes!: () => void;
  loadBanks!: () => void;
  setAccountAdded!: (value: boolean) => void;
  addAccount!: boolean;
  biPersonalInfo!: BankDataFulled | null;
  personAccount: PersonAccount = null
  bankAccount = {
    bankId: -1,
    accountType: '',
    accountNumber: '',
  };

  financialAccountData = []

  formData = {
    use: null,
    credit: null,
    creditAmount: null,
    active: false,
  }

  bank = null

  bipersonal = false
  isSystem = false
  alertTitle = '¿Es una cuenta bipersonal?'

  async mounted () {
    const { id } = this
    this.loadingForm = true
    this.initBankData()
    if (!isNaN(id)) {
      await this.getBankInfo(id)
    }
    this.loadBanks()
    this.loadAccountTypes()
    this.loadingForm = false
  }

  async getBankInfo (id) {
    const bankAccount = await this.fetchData({
      query: { name: 'fetch', model: 'BankData', params: { id } },
      force: true,
    })

    if (bankAccount.metadata) {
      this.formData.credit = bankAccount.metadata.credit
      this.formData.creditAmount = bankAccount.metadata.credit_amount
    }

    this.bankAccount = {
      bankId: bankAccount.bank.id,
      accountType: bankAccount.accountType.name,
      accountNumber: bankAccount.accountNumber,
    }

    const personAccount = await this.fetchData({
      query: { name: 'find', model: 'PersonAccount' },
      filter: {
        _and: [
          { id_bank_data: { _eq: id } },
          { id_person: { _eq: bankAccount.holder.id } }],
      },
      force: true,
    })

    if (personAccount.length) {
      this.personAccount = personAccount[0]
      const financialAccount = await this.fetchData({
        query: { name: 'find', model: 'FinancialAccount' },
        filter: {
          _and: [
            { id_person_account: { _eq: personAccount[0].id } },
            { active: { _eq: true } },
          ],
        },
        force: true,
      })

      this.formData.active = personAccount[0].active
      if (financialAccount.length) {
        this.financialAccountData = financialAccount
        this.formData.use = financialAccount.map(item => item.category)
      }
    }
  }

  get accountDisabled (): boolean {
    return isEmpty(this.bankAccount.accountNumber) || !this.addAccount
  }

  findBankAccount!: ({ bankId, personId }: {
    bankId: string;
    personId: string;
  }) => void;

  findBankAccountData (val: string): void {
    const { getFoundPerson } = this
    if (!getFoundPerson?.id || !val?.length) return

    this.findBankAccount({
      bankId: val,
      personId: getFoundPerson.id.toString(),
    })
  }

  confirmAddBiPersonalData (): void {
    const { getFoundPerson, biPersonalInfo } = this
    if (getFoundPerson?.id && biPersonalInfo) {
      this.updateBankDetails({
        bank: biPersonalInfo,
        personId: getFoundPerson.id.toString(),
        biPersonal: true,
      })
    }
    this.setBiPersonalInfo(null)
    this.$router.back()
  }

  cleanBiPersonal (): void {
    this.openAlert = false
    this.initBankData()
  }

  @Watch('biPersonalInfo', { immediate: true, deep: true }) onBiPersonalFound (
    val: BankDataFulledBiPersonal
  ): void {
    if (val && val.id && val.personAccounts) {
      this.alertSubtitle = `Cotitular: ${val.personAccounts.person?.firstName} ${val.personAccounts.person?.surname} / Rut: ${val.personAccounts.person?.rut}`
      this.openAlert = true
    }
  }

  @Watch('accountFound', { immediate: true, deep: true }) onAccountFound (
    val: BankDataFulled
  ): void {
    if (val && val.idBank) {
      this.bankAccount.bankId = val.idBank
      this.bankAccount.accountType = val.accountType.name
      this.bipersonal = val?.bipersonal
    }
  }

  initBankData (): void {
    this.bankAccount = {
      bankId: -1,
      accountType: '-1',
      accountNumber: '',
    }
  }

  async sendBankDetails (): Promise<void> {
    if (!this.$refs.form.validate()) return
    this.loadingForm = true
    const { getFoundPerson, isSystem, formData, personAccount } = this

    if (getFoundPerson?.id && !formData.active && personAccount?.id) {
      await this.pushData({
        model: 'PersonAccount',
        fields: {
          id: personAccount.id,
          active: formData.active,
        },
      })

      const financialAccounts = await this.fetchData({
        query: { name: 'find', model: 'FinancialAccount' },
        filter: {
          _and: [
            { id_person_account: { _eq: personAccount.id } },
            { active: { _eq: true } },
          ],
        },
      })
      await this.setFinancialFalse(financialAccounts)

      await this.close()
      return
    }

    if (getFoundPerson.id) {
      if (!this.accountFound) {
        this.bank = await this.createBankDetails({
          bank: this.bankAccount,
          personId: getFoundPerson.id.toString(),
        })
      } else {
        this.accountFound.idBank = this.bankAccount.bankId
        this.accountFound.accountType.name = this.bankAccount.accountType
        this.bank = await this.updateBankDetails({
          bank: this.accountFound,
          personId: getFoundPerson.id.toString(),
          biPersonal: this.bipersonal,
        })
      }
    }

    if (isSystem) {
      await this.handleExtraInformation(this.bank.personAccount.id, this.bank.bankData.id)
    }

    this.loadingForm = false
    this.$router.back()
  }

  async handleExtraInformation (idPersonAccount, idBankData) {
    const { formData, financialAccountData } = this

    if (formData?.use?.length) {
      const use = formData?.use.filter(use => financialAccountData.every(financial => financial.category.name !== use.name))

      await Promise.all(use.map(async item => {
        const exist = await this.fetchData({
          query: { name: 'find', model: 'FinancialAccount' },
          filter: {
            _and: [
              { id_person_account: { _eq: idPersonAccount } },
              { financial_category: { _eq: item.name } },
            ],
          },
        })

        if (!exist?.length) {
          return this.pushData({
            model: 'FinancialAccount',
            fields: {
              id_person_account: idPersonAccount,
              financial_category: item.name,
              active: true,
            },
          })
        } else {
          return this.pushData({
            model: 'FinancialAccount',
            fields: {
              id: exist[0].id,
              active: true,
            },
          })
        }
      }))

      const financials = financialAccountData.filter(financial => formData.use.every(use => use.name !== financial.category.name))

      await this.setFinancialFalse(financials)
    }

    await this.pushData({
      model: 'BankData',
      fields: {
        id: idBankData,
        metadata: {
          credit: formData.credit,
          credit_amount: formData.creditAmount,
        },
      },
    })
  }

  async setFinancialFalse (financials) {
    await Promise.all(financials.map(item => this.pushData({
      model: 'FinancialAccount',
      fields: {
        id: item.id,
        active: false,
      },
    })))
  }

  @Watch('getFoundPerson', { immediate: true, deep: true })
  async onPersonChange (val) {
    if (val?.details?.id) {
      const existPerson = await this.fetchData({
        query: { name: 'fetch', model: 'Person', params: { id: val?.details?.id } },
      })

      if (existPerson.type.name === 'system' && !this.isSysOps) {
        await this.$router.push({ name: 'forbidden' })
      } else if (existPerson.type.name === 'system' && this.isSysOps) {
        this.isSystem = true
      }
    }
  }

  @Watch('isSystem', { immediate: true, deep: true })
  async onSystemChange (val) {
    if (val) {
      this.categories = await this.fetchData({
        query: { name: 'find', model: 'FinancialCategory' },
      })
    }
  }
  }
